<template>
  <v-container fluid>
    <!-- User Statistics Section -->
    <v-card class="mb-6 stats-card" elevation="4">
      <v-card-title class="py-4 stats-title">
        <v-icon left color="primary" size="28" class="mr-3"
          >mdi-account-group</v-icon
        >
        <span class="title-text">User Statistics</span>
        <v-chip color="primary" small class="ml-3" outlined>
          Online Consumer Panel
        </v-chip>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text
        class="pt-6 stats-container"
        v-if="count && count.length > 0"
      >
        <!-- Total and Mobile Users (Top Section) -->
        <v-row align="center" class="mb-6">
          <v-col cols="12" md="6" lg="3">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 8 : 2"
                class="stat-card pa-4 transition-swing"
                color="primary"
                dark
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center">
                    <v-icon left>mdi-account-multiple</v-icon>
                    <div class="stat-label text-uppercase">Total Users</div>
                  </div>
                  <div class="stat-number">
                    {{ count[0].totalCount.toLocaleString() }}
                  </div>
                </div>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 8 : 2"
                class="stat-card pa-4 transition-swing"
                color="teal"
                dark
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center">
                    <v-icon left>mdi-cellphone</v-icon>
                    <div class="stat-label text-uppercase">Mobile Users</div>
                  </div>
                  <div class="stat-number">
                    {{ getMobileCount().toLocaleString() }}
                  </div>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>

        <!-- Countries Section with Search and Scrollable Container -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card flat class="pa-4 mb-4 search-card">
              <v-row align="center">
                <v-col cols="12" md="6">
                  <div class="text-subtitle-1 font-weight-medium">
                    Countries ({{ filteredCountries.length }})
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="countrySearch"
                    label="Search Countries"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card>

            <!-- Scrollable Country List -->
            <div class="countries-container">
              <v-row dense>
                <v-col
                  v-for="country in filteredCountries"
                  :key="country.country"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 6 : 2"
                      class="stat-card country-card pa-4 transition-swing"
                      :class="{ 'on-hover': hover }"
                    >
                      <div class="d-flex align-center">
                        <v-avatar
                          :color="getCountryColor(country.country)"
                          size="36"
                          class="mr-3"
                        >
                          <span class="white--text">{{
                            country.country.charAt(0)
                          }}</span>
                        </v-avatar>
                        <div class="flex-grow-1">
                          <div
                            class="d-flex justify-space-between align-center"
                          >
                            <div class="stat-label font-weight-bold">
                              {{ country.country }}
                            </div>
                            <div class="stat-number">
                              {{ country.total.toLocaleString() }}
                            </div>
                          </div>
                          <div class="stat-subtext mt-1">
                            Mobile:
                            {{
                              getCountryMobileCount(country).toLocaleString()
                            }}
                            ({{ getCountryMobilePercentage(country) }}%)
                          </div>
                          <v-progress-linear
                            :value="getPercentage(country.total)"
                            height="6"
                            class="mt-2"
                            :color="getCountryColor(country.country)"
                            background-color="grey lighten-3"
                            rounded
                          />
                        </div>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else class="text-center py-8 stats-container">
        <v-icon size="48" color="grey lighten-1">mdi-database-off</v-icon>
        <div class="mt-2 grey--text">No statistics available</div>
      </v-card-text>
    </v-card>

    <!-- Filter Section -->
    <v-card class="mb-6" elevation="2">
      <v-card-title class="py-4">
        <v-icon left color="primary" class="mr-2">mdi-filter</v-icon>
        Filter Users
        <v-spacer></v-spacer>
        <v-chip color="primary" class="font-weight-medium">
          Total: {{ totalCount.toLocaleString() }}
        </v-chip>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6">
        <v-form @submit.prevent="applyFilters" ref="filterForm">
          <!-- Basic Filters -->
          <v-row>
            <v-col cols="12" class="pb-2">
              <div class="text-subtitle-1 font-weight-medium mb-2">
                Basic Information
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="filters.email"
                label="Email"
                placeholder="Search by email"
                clearable
                prepend-inner-icon="mdi-email"
                outlined
                :rules="[rules.email]"
                hint="Enter full or partial email"
                persistent-hint
                class="filter-field"
                height="48"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="filters.country"
                :items="countries"
                label="Country"
                placeholder="Select country"
                clearable
                prepend-inner-icon="mdi-earth"
                outlined
                :menu-props="{ maxHeight: 300 }"
                hint="Select user's country"
                persistent-hint
                class="filter-field"
                height="48"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="filters.gender"
                :items="genders"
                label="Gender"
                placeholder="Select gender"
                item-text="label"
                item-value="value"
                clearable
                prepend-inner-icon="mdi-gender-male-female"
                outlined
                hint="Filter by gender"
                persistent-hint
                class="filter-field"
                height="48"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="filters.source"
                :items="source"
                label="Source"
                placeholder="Select source"
                item-text="label"
                item-value="value"
                clearable
                prepend-inner-icon="mdi-login"
                outlined
                hint="Filter by registration source"
                persistent-hint
                class="filter-field"
                height="48"
              />
            </v-col>
          </v-row>

          <!-- Age Range Filter -->
          <v-row>
            <v-col cols="12" class="pb-2 pt-6">
              <div class="text-subtitle-1 font-weight-medium mb-2">
                Age Range
              </div>
            </v-col>
            <v-col cols="12">
              <v-card flat class="pa-6 age-filter-card">
                <div class="d-flex align-center mb-4">
                  <v-icon left small color="primary">mdi-calendar</v-icon>
                  <span class="text-subtitle-2 font-weight-medium"
                    >Select Age Range</span
                  >
                </div>
                <v-range-slider
                  v-model="filters.ageRange"
                  :min="minAge"
                  :max="maxAge"
                  thumb-label="always"
                  :thumb-size="28"
                  track-color="grey lighten-3"
                  track-fill-color="primary"
                  class="mt-6"
                >
                  <template v-slot:prepend>
                    <v-icon color="primary">mdi-account</v-icon>
                  </template>
                </v-range-slider>
                <div class="age-range-display mt-4">
                  <v-chip small color="primary" outlined>
                    Min: {{ filters.ageRange[0] }}
                  </v-chip>
                  <v-chip small color="primary" outlined class="ml-2">
                    Max: {{ filters.ageRange[1] }}
                  </v-chip>
                </div>
                <div class="text-caption grey--text text--darken-1 mt-3">
                  Slide to select age range for filtering users
                </div>
              </v-card>
            </v-col>
          </v-row>

          <!-- Actions -->
          <v-row class="mt-6">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="red lighten-2"
                class="mr-3"
                @click="resetFilters"
                :disabled="loading"
                outlined
                large
              >
                <v-icon left>mdi-refresh</v-icon>
                Reset
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                elevation="2"
                large
              >
                <v-icon left>mdi-check</v-icon>
                Apply Filters
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Data Table -->
    <v-card elevation="2">
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="loading"
        :server-items-length="totalUsers"
        :items-per-page="limit"
        class="elevation-0"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 50],
          'show-first-last-page': true,
        }"
      >
        <template v-slot:item.dob="{ item }">
          {{ formatDate(item.dob) }}
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop }">
          Showing {{ pageStart }} to {{ pageStop }} of {{ totalUsers }}
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="7"
          @input="fetchUsers"
          color="primary"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../services/dataServiceAdmin";

const service = new RestResource();

export default {
  name: "UserManagement",
  data: () => ({
    loading: false,
    users: [],
    totalUsers: 0,
    totalPages: 1,
    totalCount: 0,
    page: 1,
    limit: 20,
    countrySearch: "",
    filters: {
      email: "",
      country: "",
      gender: "",
      source: "",
      state: "",
      ageRange: [0, 100],
    },
    minAge: 0,
    maxAge: 100,
    headers: [
      { text: "First Name", value: "firstName", width: "150px" },
      { text: "Last Name", value: "lastName", width: "150px" },
      { text: "Country", value: "country", width: "120px" },
      { text: "Email", value: "email", width: "200px" },
      { text: "Date of Birth", value: "dob", width: "120px" },
      { text: "Gender", value: "gender", width: "100px" },
      { text: "State", value: "state", width: "120px" },
      { text: "City", value: "city", width: "120px" },
    ],
    genders: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
    ],
    source: [{ label: "Mobile", value: "mobile" }],
    states: [],
    countries: [],
    count: [],
    rules: {
      email: (value) => {
        if (!value) return true;
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || "Invalid email address";
      },
    },
  }),
  computed: {
    filteredCountries() {
      if (!this.count[0]?.countries) return [];
      if (!this.countrySearch) return this.count[0].countries;

      const search = this.countrySearch.toLowerCase();
      return this.count[0].countries.filter((country) =>
        country.country.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "N/A";
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    },
    resetFilters() {
      this.filters = {
        email: "",
        country: "",
        gender: "",
        source: "",
        state: "",
        ageRange: [this.minAge, this.maxAge],
      };
      this.$refs.filterForm.resetValidation();
      this.page = 1;
      this.fetchUsers();
    },
    async fetchUsers() {
      this.loading = true;
      try {
        const payload = {
          page: this.page,
          limit: this.limit,
          ...this.filters,
        };

        if (
          this.filters.ageRange[0] === this.minAge &&
          this.filters.ageRange[1] === this.maxAge
        ) {
          delete payload.ageRange;
        }

        const response = await service.fetchOnlinePanelUsers(payload);
        this.users = response.data.docs;
        this.totalUsers = response.data.totalUsers;
        this.totalPages = response.data.totalPages;
        this.totalCount = response.data.totalDocs;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Failed to fetch users: " + error.message,
        });
      } finally {
        this.loading = false;
      }
    },

    applyFilters() {
      this.page = 1;
      this.fetchUsers();
    },

    getPercentage(count) {
      return this.count[0]?.totalCount
        ? (count / this.count[0].totalCount) * 100
        : 0;
    },

    getCountryColor(country) {
      const colors = {
        Malaysia: "teal",
        Singapore: "deep-purple",
        Thailand: "amber",
        Indonesia: "orange",
        default: "blue-grey",
      };
      return colors[country] || colors.default;
    },

    getMobileCount() {
      if (!this.count[0] || !this.count[0].countries) return 0;
      return this.count[0].countries.reduce((total, country) => {
        const mobileSource = country.sources.find((s) => s.source === "mobile");
        return total + (mobileSource ? mobileSource.count : 0);
      }, 0);
    },

    getCountryMobileCount(country) {
      const mobileSource = country.sources.find((s) => s.source === "mobile");
      return mobileSource ? mobileSource.count : 0;
    },

    getCountryMobilePercentage(country) {
      const mobileCount = this.getCountryMobileCount(country);
      return country.total > 0
        ? Math.round((mobileCount / country.total) * 100)
        : 0;
    },

    async initializeData() {
      this.loading = true;
      try {
        const [states, countries, count] = await Promise.all([
          service.fetchUniqueStates(),
          service.fetchUniqueCountries(),
          service.getTotalUserCount(),
        ]);

        this.states = states.data;
        this.countries = countries.data;
        this.count = Array.isArray(count.data)
          ? count.data
          : count.data.count
          ? [count.data.count]
          : [];
      } catch (error) {
        console.error("Failed to load initial data:", error);
        this.$notify({
          type: "error",
          text: "Failed to load initial data: " + error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },

  created() {
    this.initializeData();
    this.fetchUsers();
  },
};
</script>

<style scoped>
.stats-card {
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
}

.stats-container {
  background-color: #f8f9fa; /* Subtle grey background for contrast */
  border-radius: 8px;
  padding: 24px;
}

.stats-title {
  display: flex;
  align-items: center;
  padding: 16px 24px !important;
  background-color: #ffffff;
}

.title-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a237e; /* Deep indigo for better contrast */
  letter-spacing: 0.5px;
}

.stat-card {
  border-radius: 8px;
  transition: all 0.3s ease;
  min-height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.country-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.search-card {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
}

.stat-label {
  font-size: 0.9rem;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.stat-number {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
}

.stat-subtext {
  font-size: 0.8rem;
  opacity: 0.7;
}

.transition-swing {
  transition: transform 0.2s ease;
}

.v-card.on-hover {
  transform: translateY(-4px);
}

.countries-container {
  max-height: 600px;
  overflow-y: auto;
  padding-right: 16px;
  margin-right: -16px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.countries-container::-webkit-scrollbar {
  width: 8px;
}

.countries-container::-webkit-scrollbar-track {
  background: transparent;
}

.countries-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.countries-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 960px) {
  .stats-title {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px !important;
  }

  .title-text {
    font-size: 1.15rem;
  }

  .v-chip {
    margin-top: 8px;
    margin-left: 0 !important;
  }

  .stat-card {
    min-height: 90px;
  }

  .stat-number {
    font-size: 1.1rem;
  }

  .stat-label {
    font-size: 0.85rem;
  }

  .stat-subtext {
    font-size: 0.75rem;
  }

  .countries-container {
    max-height: 400px;
  }
}

@media (max-width: 600px) {
  .stats-title {
    padding: 12px !important;
  }

  .title-text {
    font-size: 1.1rem;
  }

  .stat-card {
    min-height: 80px;
  }

  .stat-number {
    font-size: 1rem;
  }

  .stat-label {
    font-size: 0.8rem;
  }

  .stat-subtext {
    font-size: 0.7rem;
  }

  .countries-container {
    max-height: 300px;
  }
}
</style>
